export const STARTING_WORDS = [
  "talk",
  "walk",
  "push",
  "look",
  "cry",
  "smile",
  "study",
  "search",
  "create",
  "discover",
  "calculate",
  "inform",
  "see",
  "get",
  "learn",
  "think",
  "image",
  "like",
  "love",
  "hate",
  "promise",
  "surprise",
  "plan",
  "concern",
  "doubt",
  "get",
  "assume",
  "intent",
  "be",
  "become",
  "seem",
  "have",
  "range",
  "evaporate",
  "flow",
  "vibrate",
  "rotate",
  "square",
  "angle",
  "sharp",
  "number",
  "heavy",
  "high",
  "hot",
  "hard",
  "wet",
  "many",
  "infinity",
  "zero",
  "more",
  "good",
  "better",
  "red",
  "green",
  "higher",
  "fast",
  "above",
  "left",
  "forward",
  "curve",
  "direct",
  "early",
  "tomorrow",
  "far",
  "god",
  "freewill",
  "smart",
  "scientific",
  "faithful",
  "mysterious",
  "hopeful",
  "friendly",
  "selfish",
  "independent",
  "honest",
  "humble",
  "responsible",
  "lazy",
  "mature",
  "jealous",
  "brave",
  "sad",
  "curious",
  "polite",
  "difficult",
  "strict",
  "unique",
  "successful",
  "annoyed",
  "weak",
  "familiar",
  "suspicious",
  "lucky",
  "possible",
];

// MUTED
export const GRAPH_COLORS = [
  "#DCC48E",
  "#EAEFD3",
  "#B3C0A4",
  "#77789B",
  "#4A5477",
  "#DBA88E",
];

export const HEAT_MAP_COLORS = [
  "#7D97A3",
  "#B3C0A4",
  "#EFE8BA",
  "#DCC48E",
  "#D1A787",
];

export const Color = {
  POSITIVE: "#61B0AF",
  NEGATIVE: "#F28076",
  NEUTRAL: "#0A4A90",
  ACTIVE: "#1890FF",
  EMPTY: "#F0F2F5",
  HIGHLIGHT: "#FCFE15",
  HOVERED: "#000000",
  BORDER: "#FFFFFF",
  CARD_BACKGROUND: "#FFFFFF",
  PANEL_BACKGROUND: "#FAFAFA",
};

export const Shape = {
  CIRCLE: "circle",
  SQUARE: "square",
  TRIANGLE: "triangle",
};
