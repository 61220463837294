/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "wordnet-web-dev-20200124174830-hostingbucket-deva",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://wordnet-web-dev-20200124174830-hostingbucket-deva.s3-website-us-east-1.amazonaws.com",
    "aws_cognito_identity_pool_id": "us-east-1:fc8e4229-6e14-43e2-b9bf-4f894c91a1c2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_RfZuOJMxs",
    "aws_user_pools_web_client_id": "3ec7hnj9ql9bsse3ci18rklmu3",
    "oauth": {},
    "aws_mobile_analytics_app_id": "41947d638dea42d28f1d528b05933204",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_user_files_s3_bucket": "wordnet-web-dev6ba93794b4334c338ef75ccce209c62cdev-deva",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://zhybkaecyvcyvdqq6pjdf66x44.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
